// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Icon-Icon--34rs2ksc{display:inline-block;width:18px;height:18px;vertical-align:middle;fill:#212121}.Icon-Icon_smaller--1iUGAIB8{width:10px;height:10px}.Icon-Icon_small--2fog1ybm{width:14px;height:14px}.Icon-Icon_medium--2hH4tSCp{width:24px;height:24px}.Icon-Icon_large--IvMqigvl{width:30px;height:30px}.Icon-Icon_grey--1TavDrci{fill:#777}.Icon-Icon_grey-light--1soUGhlx{fill:#d8d8d8}.Icon-Icon_white--2IFxlzak{fill:#fff}.Icon-Icon_primary--PnjGDkps{fill:#fe3999}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Icon": "Icon-Icon--34rs2ksc",
	"Icon_smaller": "Icon-Icon_smaller--1iUGAIB8",
	"Icon_small": "Icon-Icon_small--2fog1ybm",
	"Icon_medium": "Icon-Icon_medium--2hH4tSCp",
	"Icon_large": "Icon-Icon_large--IvMqigvl",
	"Icon_grey": "Icon-Icon_grey--1TavDrci",
	"Icon_grey-light": "Icon-Icon_grey-light--1soUGhlx",
	"Icon_white": "Icon-Icon_white--2IFxlzak",
	"Icon_primary": "Icon-Icon_primary--PnjGDkps"
};
module.exports = ___CSS_LOADER_EXPORT___;
