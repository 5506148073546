// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FooterMenu-FooterMenu--3tGzWh5M{font-weight:600;box-shadow:0 -10px 10px rgba(0,0,0,.05);position:relative;z-index:2}.FooterMenu-FooterMenu__content--1AUlE2yy{display:flex;height:80px;align-items:center}.FooterMenu-FooterMenu--3tGzWh5M a{margin-right:20px;font-size:14px;text-transform:uppercase;transition-duration:.2s}.FooterMenu-FooterMenu_theme_light--2tZuV93f{background:#fff}.FooterMenu-FooterMenu_theme_light--2tZuV93f a{color:#777;text-decoration:none}.FooterMenu-FooterMenu_theme_light--2tZuV93f a:hover{color:#fe3999}.FooterMenu-FooterMenu_theme_dark--1dm2ceag{background:#212121}.FooterMenu-FooterMenu_theme_dark--1dm2ceag a{color:#777;text-decoration:none}.FooterMenu-FooterMenu_theme_dark--1dm2ceag a:hover{color:#fff}@media (max-width:768px){.FooterMenu-FooterMenu--3tGzWh5M{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FooterMenu": "FooterMenu-FooterMenu--3tGzWh5M",
	"FooterMenu__content": "FooterMenu-FooterMenu__content--1AUlE2yy",
	"FooterMenu_theme_light": "FooterMenu-FooterMenu_theme_light--2tZuV93f",
	"FooterMenu_theme_dark": "FooterMenu-FooterMenu_theme_dark--1dm2ceag"
};
module.exports = ___CSS_LOADER_EXPORT___;
