// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModelsListItem-ModelsListItem--2Ar_jFrF{display:block}.ModelsListItem-ModelsListItem--2Ar_jFrF a{text-decoration:none!important}.ModelsListItem-ModelsListItem__img--2SsUEQxK{display:block;line-height:0;background:#fe3999}.ModelsListItem-ModelsListItem__img--2SsUEQxK img{width:100%;transition-duration:.3s}.ModelsListItem-ModelsListItem--2Ar_jFrF:hover img{opacity:.9}.ModelsListItem-ModelsListItem__title--3nUT3Ckn{display:block;margin-top:10px;font-size:18px;font-weight:700}.ModelsListItem-ModelsListItem_theme_light--3EWzH2nC .ModelsListItem-ModelsListItem__title--3nUT3Ckn{color:#444}.ModelsListItem-ModelsListItem_theme_dark--SNv9xGfi .ModelsListItem-ModelsListItem__title--3nUT3Ckn{color:#f6f8fa}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ModelsListItem": "ModelsListItem-ModelsListItem--2Ar_jFrF",
	"ModelsListItem__img": "ModelsListItem-ModelsListItem__img--2SsUEQxK",
	"ModelsListItem__title": "ModelsListItem-ModelsListItem__title--3nUT3Ckn",
	"ModelsListItem_theme_light": "ModelsListItem-ModelsListItem_theme_light--3EWzH2nC",
	"ModelsListItem_theme_dark": "ModelsListItem-ModelsListItem_theme_dark--SNv9xGfi"
};
module.exports = ___CSS_LOADER_EXPORT___;
